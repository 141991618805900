<script>
import { Button, Col, Input, Row } from 'ant-design-vue'
import apiTool from '@/command/apiTool'
import MapLatlng from '@/components/MapLatlng'
import MapLatlngMxc from '@/components/MapLatlngMxc'
import MapView from '@/components/MapView'
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    setValue: {
      type: Function,
      default: null,
    },
    getValue: {
      type: Function,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
  },
  methods: {
    onChange(val) {
      if (val) {
        let latlng = []
        if (val.includes(',')) {
          latlng = val.split(',')
        } else if (val.includes('，')) {
          latlng = val.split('，')
        }
        this.item.keys.forEach((e, i) => {
          console.log(e, latlng[i])
          this.setValue(e, latlng[i])
        })
      }
    },
    onOpenMap() {
      const appCode = localStorage.getItem('appCode').toLocaleLowerCase()
      let view = MapLatlng
      let url = require('./asset/hand-draw-map-xiangshan-2.png')
      let isTileLayer = false
      if (appCode === 'dyz') {
        view = MapView
        url = 'https://dayanzhen-1306707845.cos-website.ap-shanghai.myqcloud.com/dayanzhen/[z]/[x]/[y].png'
        isTileLayer = true
      } else if (appCode === 'mxc') {
        view = MapView
        url = "https://mxmaph5-1306707845.cos.ap-shanghai.myqcloud.com/[z]/[x]/[y].png"
        isTileLayer = true
      } else if (appCode === 'dswl') {
        view = MapLatlng
        url = "https://maps-wechat-1306707845.cos.ap-shanghai.myqcloud.com/hand-draw-map-daishan.png"
        isTileLayer = false
      }
      apiTool.openMap({
        title: '打开地图',
        value: [],
        view,
        url,
        isTileLayer,
        // view: this.item.imgName ? MapLatlngMxc : MapLatlng,
        // url: this.item.imgName
        //   ? require(`./asset/hand-draw-map-${this.item.imgName}-2.png`)
        //   : require('./asset/hand-draw-map-xiangshan-2.png'),
        onSuccess: (data) => {
          const { lat, lng } = data
          let text = `${lat}，${lng}`
          if (Array.isArray(this.item.keys) && this.item.keys.length == 2) {
            const latlng = [lat, lng]
            this.item.keys.forEach((e, i) => {
              this.setValue(e, latlng[i])
            })
            this.$forceUpdate()
          }
        },
      })
    },
    getValueData() {
      const keys = (this.item.keys || [])
        .map((e) => {
          return this.getValue({ key: e })
        })
        .filter((e) => e)
      if (keys.length == 0) return ''
      return keys.toString()
    },
  },
  render() {
    return (
      <Row gutter={10}>
        <Col span={20}>
          <Input value={this.getValueData()} placeholder="请选择定位" onChange={(e) => this.onChange(e.target.value)} />
        </Col>
        <Col span={4}>
          <Button type="primary" onClick={this.onOpenMap}>
            打开定位
          </Button>
        </Col>
      </Row>
    )
  },
}
</script>
