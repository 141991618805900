<template>
  <pro-layout
    :menus="menus"
    :collapsed="collapsed"
    :media-query="query"
    :is-mobile="isMobile"
    :handle-media-query="handleMediaQuery"
    :handle-collapse="handleCollapse"
    :i18n-render="i18nRender"
    v-bind="settings"
    :collapsedButtonRender="collapsedButtonRender"
    :open-keys="openKeys"
    :selected-keys="selectedKeys"
  >
    <!-- Ads begin
      广告代码 真实项目中请移除
      production remove this Ads
    -->
    <!-- <ads v-if="isProPreviewSite && !collapsed"/> -->
    <!-- Ads end -->

    <!-- 1.0.0+ 版本 pro-layout 提供 API，
          我们推荐使用这种方式进行 LOGO 和 title 自定义
    -->
    <template v-slot:menuHeaderRender>
      <div>
        <img v-show="collapsed == false" :src="logo2" class="logo_box" />
        <img v-show="collapsed == true" src="../assets/logo.png" class="logo_box2" />
        <!-- <h1>{{ title }}</h1> -->
      </div>
    </template>
    <!-- 1.0.0+ 版本 pro-layout 提供 API,
          增加 Header 左侧内容区自定义
    -->
    <template v-slot:headerContentRender>
      <div class="header-left">
        <a-tooltip title="刷新页面">
          <a-icon type="reload" style="font-size: 16px; cursor: pointer" @click="reloadPage" />
        </a-tooltip>
        <a-breadcrumb v-if="showBread == true && !isMobile" style="margin-left: 20px">
          <a-breadcrumb-item v-for="(item, index) in routes" :key="index">
            <a :href="item.path || '/dataKanban/kanban'">{{ item.breadcrumbName }}</a>
          </a-breadcrumb-item>
        </a-breadcrumb>
      </div>
    </template>

    <!-- <setting-drawer :settings="settings" @change="handleSettingChange">
      <div style="margin: 12px 0">
        This is SettingDrawer custom footer content.
      </div>
    </setting-drawer> -->
    <!-- 右边内容区  登录用户信息 -->

    <template v-slot:rightContentRender>
      <right-content :top-menu="settings.layout === 'topmenu'" :is-mobile="isMobile" :theme="settings.theme" />
    </template>
    <!-- custom footer / 自定义Footer -->
    <template v-slot:footerRender>
      <div></div>
    </template>
    <div>
      <multi-tab @close="this.tabClose" />
      <transition :name="page-transition">
        <div class="view-box">
          <keep-alive  :max="10"  :exclude="['business']"   >
              <router-view :key="$route.fullPath" v-if="$route.meta.keepAlive" />
          </keep-alive>
           <router-view v-if="!$route.meta.keepAlive"  />
        </div>
      </transition>
    </div>
  </pro-layout>
</template>

<script>
import { SettingDrawer, updateTheme } from '@ant-design-vue/pro-layout'
import { i18nRender } from '@/locales'
import { mapState } from 'vuex'
import { CONTENT_WIDTH_TYPE, SIDEBAR_TYPE, TOGGLE_MOBILE_TYPE } from '@/store/mutation-types'

import defaultSettings from '@/config/defaultSettings'
import RightContent from '@/components/GlobalHeader/RightContent'
// import GlobalFooter from '@/components/GlobalFooter'
import Ads from '@/components/Other/CarbonAds'
import { asyncRouterMap } from '@/config/router.config.js'
import menu from '@/locales/lang/en-US/menu'
import { getAction } from '@/command/netTool'
import { UserLayout, BasicLayout,RouteView } from '@/layouts'
import logo from '@/assets/logo.png'
import logo2 from '@/assets/logo2.png'

// const RouteView = {
//   name: 'RouteView',
//   render: h => h('router-view')
// }

export default {
  name: 'basic-layout',
  components: {
    SettingDrawer,
    RightContent,
    // GlobalFooter,
    Ads,
  },
  data() {
    return {
      openKeys: ['/commodityManagement'],
      selectedKeys: ['/report/scenicSpotSignage'],
      // 用于控制是否显示面包屑
      showBread: true,
      // 面包屑路由
      routes: [],
      // preview.pro.antdv.com only use.
      isProPreviewSite: process.env.VUE_APP_PREVIEW === 'true' && process.env.NODE_ENV !== 'development',
      // end

      // base
      menus: [],
      // 侧栏收起状态
      collapsed: false,
      title: defaultSettings.title,
      settings: {
        // 布局类型
        layout: defaultSettings.layout, // 'sidemenu', 'topmenu'
        // CONTENT_WIDTH_TYPE
        contentWidth: defaultSettings.layout === 'sidemenu' ? CONTENT_WIDTH_TYPE.Fluid : defaultSettings.contentWidth,
        // 主题 'dark' | 'light'
        theme: defaultSettings.navTheme,
        // 主色调
        primaryColor: defaultSettings.primaryColor,
        fixedHeader: defaultSettings.fixedHeader,
        fixSiderbar: defaultSettings.fixSiderbar,
        colorWeak: defaultSettings.colorWeak,
        hideHintAlert: false,
        hideCopyButton: false,
        openKeys: ['/commodityManagement'],
      },
      // 媒体查询
      query: {},

      // 是否手机模式
      isMobile: false,
      sysIcon:"",
      logo2:localStorage.getItem('logo') || logo2
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      mainMenu: (state) => state.permission.addRouters,
    }),
  },
  watch: {
    //************************* */
    openKeys(a, b) {
      if (
        a.indexOf('orderProcessing') !== -1 ||
        a.indexOf('audit') !== -1 ||
        b.indexOf('orderProcessing') !== -1 ||
        b.indexOf('audit') !== -1
      ) {
        if (process.env.NODE_ENV !== 'development') {
          this.$nextTick(() => {
            this.getMenuNumber()
          })
        }
      }
    },
  },
  created() {
    
    getAction('farmConfig/detail').then(res=>{
        this.sysIcon = res.data.sysIcon || logo
    })
    // 初始化面包屑路由
    this.initBreadRouters()
    // const routes = this.mainMenu.find((item) => item.path === '/')
    // this.menus = (routes && routes.children) || []
    // // 处理侧栏收起状态
    this.$watch('collapsed', () => {
      this.$store.commit(SIDEBAR_TYPE, this.collapsed)
    })
    this.$watch('isMobile', () => {
      this.$store.commit(TOGGLE_MOBILE_TYPE, this.isMobile)
    })
    this.$watch('$route', () => {
      this.getQiniuToken()
      this.initBreadRouters()
    })
    window.addEventListener('resize', () => {
      // this.listenMove()
      // if (document.body.clientWidth < 700) {
      //   this.collapsed = true
      // } else {
      //   if (this.collapsed) {
      //     this.collapsed = false
      //   }
      // }
      if (document.body.clientWidth < 912) {
        this.collapsed = true
      } else {
        if (document.body.clientWidth < 1000) {
          this.showBread = false
        } else {
          if (this.showBread == false) {
            this.showBread = true
          }
        }
      }
    })

    window.addEventListener('setItemEvent', (e) => {
      this.openKeys = e.newValue
    })

    //********************************** */
    if (process.env.NODE_ENV === 'development') {
      this.getQiniuToken()
      const routes = asyncRouterMap.find((item) => item.path === '/')
      this.menus = (routes && routes.children) || []
    } else {
      localStorage.setItem('VERSION', 'test')
      this.getMenuNumber()
      this.getQiniuToken()
    }
    // localStorage.setItem('VERSION', 'test')
    // this.getMenuNumber()
    // this.getQiniuToken()
  },
  mounted() {
    const userAgent = navigator.userAgent
    if (userAgent.indexOf('Edge') > -1) {
      this.$nextTick(() => {
        this.collapsed = !this.collapsed
        setTimeout(() => {
          this.collapsed = !this.collapsed
        }, 16)
      })
    }

    // first update color
    // TIPS: THEME COLOR HANDLER!! PLEASE CHECK THAT!!
    if (process.env.NODE_ENV !== 'production' || process.env.VUE_APP_PREVIEW === 'true') {
      updateTheme(this.settings.primaryColor)
    }

    //* 数据字典
    this.$store.dispatch('getProductType')
    this.$store.dispatch('getGoodsType')
    this.$store.dispatch('getTicketType')
    this.$store.dispatch('getHotelType')
    this.$store.dispatch('getSecondaryType')
  },
  methods: {
    tabClose(e){
       //  this.$refs.currentRouter.$destroy()
      // this.$refs.currentRouter._isDestroyed = true

    },
    menuRender() {
      return <div>123</div>
    },
    // 获取七牛token
    getQiniuToken() {
      getAction('/api/common/getQnToken').then((e) => localStorage.setItem('qiniu', JSON.stringify(e.data)))
      getAction('/api/common/v2/getQnToken').then((e) => localStorage.setItem('qiniu-v2', JSON.stringify(e.data)))
    },
    // 获取菜单数量
    getMenuNumber() {
      getAction('/api/base/menuCount/countAllRecord').then((e) => {
        this.getMenu(e.data)
      })
    },
    i18nRender,
    getComponent(item) {
      if (item.component == 'RouteView') {
        return RouteView
      } else if (item.component == 'BasicLayout') {
        return BasicLayout
      } else {
        return () => import(`@/views/${item.path}`)
      }
    },
    getSvgInit() {
      let arr = require.context('../assets/icons/', true, /\.svg$/).keys()
      let obj = {}
      arr.forEach((e) => {
        let a = e.substring(e.indexOf('./') + 2, e.indexOf('.svg'))
        obj[a] = require(`@/assets/icons/${a}.svg?inline`).default
      })
      return obj
    },
    getChildren(children = [], obj, objSvg, button, path) {
      const mChildren = children.filter((e) => e.type !== '1')
      const mButton = children.filter((e) => e.type == '1')
      if (mButton.length > 0) {
        button[path] = mButton
      }
      if (mChildren.length > 0) {
        const numObj = {
          orderProcessing: obj.totalOrder > 0 && this.openKeys.indexOf('/orderProcessing') == -1 ? obj.totalOrder : '',
          localProducts: obj.order > 0 ? obj.order : '',
          refund: obj.refund > 0 ? obj.refund : '',
          feedback: obj.feedback > 0 ? obj.feedback : '',
          audit: obj.totalAudit > 0 && this.openKeys.indexOf('/audit') == -1 ? obj.totalAudit : '',
          settlementAudit: obj.settlement > 0 ? obj.settlement : '',
        }
        const path = mChildren[0].path
        console.log('path', path)
        return mChildren.map((e) => {
          return {
            ...e,
            name: e.title,
            component: this.getComponent(e),
            hidden: e.display !== '0' ? true : false,
            redirect: path ? path : '',
            meta: {
              title: e.name,
              subTitle: e.subtitle,
              keepAlive: e.keepAlive == '0' ? true : false,
              icon: objSvg[e.icon],
              num: numObj[e.title],
            },
            children: this.getChildren(e.children, obj, objSvg, button, e.path),
          }
        })
      } else {
        return null
      }
    },
    getMenu(obj) {
      getAction('/admin/menu/userTree?sysCode=sznc', {}, '/bpi').then((e) => {
        const button = {}
        const menus = this.getChildren(e.data, obj, this.getSvgInit(), button)
        this.menus = menus
        localStorage.setItem('button', JSON.stringify(button))
      })
    },
    onMove() {
      if (this.collapsed) {
        this.collapsed = false
      }
    },
    listenMove() {
      if (document.body.clientWidth > 660) {
        // document.getElementsByClassName('ant-layout-sider-children')[0].removeEventListener('mousemove',this.onMove)
        // document.getElementsByClassName('ant-layout-sider-children')[0].addEventListener('mousemove', this.onMove)
      }
    },
    reloadPage() {
      this.$router.go(0)
    },
    initBreadRouters() {
      const urlFilter = [
        '/orderProcessing',
        '/commodityManagement',
        '/marketingCenter',
        '/platform',
        '/settlementAudit',
        '/publicInfo',
      ]
      const matcheds = this.$route.matched
      const routeList = this.getAllList(matcheds)
      this.routes = routeList.map((e) => ({
        path: urlFilter.includes(e.path) ? 'javascript:;' : e.path,
        breadcrumbName: e.breadcrumbName || e.meta.title,
      }))
    },
    getAllList(arr) {
      var res = []
      for (let i = 0; i < arr.length; i++) {
        if (Array.isArray(arr[i].meta.breadRouters)) {
          res = res.concat(...arr[i].meta.breadRouters)
          res.push(arr[i])
        } else {
          res.push(arr[i])
        }
      }
      return res
    },
    handleMediaQuery(val) {
      this.query = val
      if (this.isMobile && !val['screen-xs']) {
        this.isMobile = false
        return
      }
      if (!this.isMobile && val['screen-xs']) {
        this.isMobile = true
        this.collapsed = false
        this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fluid
        // this.settings.fixSiderbar = false
      }
    },
    handleCollapse(val) {
      this.collapsed = val
    },
    handleSettingChange({ type, value }) {
      type && (this.settings[type] = value)
      switch (type) {
        case 'contentWidth':
          this.settings[type] = value
          break
        case 'layout':
          if (value === 'sidemenu') {
            this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fluid
          } else {
            this.settings.fixSiderbar = false
            this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fixed
          }
          break
      }
    },
    collapsedButtonRender(h, collapsed) {
      if (collapsed) {
        return (
          <div>
            <span
              class="iconfont icon-mengbanzu3"
              style={{ fontSize: '16px', fontWeight: '900', color: '#000' }}
            ></span>
          </div>
        )
      } else {
        return (
          <div>
            <span
              class="iconfont icon-mengbanzu3-copy"
              style={{ fontSize: '16px', fontWeight: '900', color: '#000' }}
            ></span>
          </div>
        )
      }
    },
  },
}
</script>

<style lang="less">
@import './BasicLayout.less';
</style>
<style lang="less" scoped>
.view-box {
  margin-top: -20px;
  overflow-y: auto;
  max-height: 88vh;
  &::-webkit-scrollbar {
    display: none;
  }
}

.header-left {
  display: flex;
  align-items: center;
  height: 100%;
}
.pro-layout-ui {
  background: red;
}
.logo_box {
  width: 89%;
  height: auto;
  margin-left: -4%;
}
.logo_box2 {
  width: 115%;
  height: auto;
  margin-left: -25%;
}
/deep/.ant-breadcrumb {
  span {
    &:nth-last-child(1) {
      .ant-breadcrumb-link {
        font-weight: 600;
      }
    }
  }
}
</style>
