<script>
import { Input, InputNumber } from 'ant-design-vue'
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    setValue: {
      type: Function,
      default: null,
    },
    getValue: {
      type: Function,
      default: null,
    },
  },
  methods: {
    onChange(key) {
      return (value) => {
        if (key) {
          if (parseFloat(value) >= 0) {
            this.setValue(key, value.toString())
          }
        }
      }
    },
  },
  render() {
    return (
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <InputNumber
          formatter={(value) => {
            if (value) {
              if (this.item.dataType == 'int') {
                return Number(value)
              } else {
                return value
              }
            } else {
              return value
            }
          }}
          placeholder="请输入最小值"
          style={{ display: 'flex', flex: 1 }}
          value={this.getValue({ key: this.item.keys[0] })}
          onChange={this.onChange(this.item.keys[0])}
        />
        <span style={{ marginLeft: '10px', marginRight: '10px' }}>~</span>
        <InputNumber
          formatter={(value) => {
            if (value) {
              if (this.item.dataType == 'int') {
                return Number(value)
              } else {
                return value
              }
            } else {
              return value
            }
          }}
          placeholder="请输入最大值"
          style={{ display: 'flex', flex: 1 }}
          value={this.getValue({ key: this.item.keys[1] })}
          onChange={this.onChange(this.item.keys[1])}
        />
      </div>
    )
  },
}
</script>
