<script>
import { DatePicker } from 'ant-design-vue'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN'
import moment from 'moment'
export default {
  props: ['value', 'mode', 'item'],
  render() {
    const onDisabledDate = (current) => {
      if (this.item.canUseDate) {
        const value = moment(current.format('YYYY-MM-DD')).valueOf() + ''
        if (this.item.canUseDate.includes(value)) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    }
    return (
      <DatePicker
        props={this.item.props}
        style={{ width: '100%' }}
        disabled={this.mode == 'detail'}
        value={this.value}
        disabledDate={onDisabledDate}
        locale={locale}
        onChange={(value) => {
          if (this.item.formatdate && typeof this.item.formatdate === 'function') {
            let formatdate = this.item.formatdate(value)
            this.$emit('change', formatdate)
          } else {
            this.$emit('change', value)
          }
          if (this.item.onDateSelect) {
            this.item.onDateSelect(value)
          }
        }}
      />
    )
  },
}
</script>
