<script>
import { Checkbox, Button } from 'ant-design-vue'
export default {
  props: {
    typeData: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    setChange(data) {
      if (this.item.onChange) {
        this.item.onChange(data)
      }
    },
    onCheckItem(item) {
      const arr = new Set(this.value)
      const find = this.typeData.find((e) => e.type == 'all')
      const maxlength = this.item?.props?.max || null

      if (arr.has(item.value)) {
        if (item.type == 'all') {
          this.setChange([])
          this.$emit('change', [])
        } else {
          arr.delete(item.value)
          if (this.value.length == this.typeData.length) {
            if (find) {
              this.setChange(Array.from(arr).filter((e) => e !== find.value))
              this.$emit(
                'change',
                Array.from(arr).filter((e) => e !== find.value)
              )
            } else {
              this.setChange(Array.from(arr))
              this.$emit('change', Array.from(arr))
            }
          } else {
            this.setChange(Array.from(arr))
            this.$emit('change', Array.from(arr))
          }
        }
      } else {
        if (item.type == 'all') {
          this.setChange(this.typeData.map((e) => e.value))
          this.$emit(
            'change',
            this.typeData.map((e) => e.value)
          )
        } else {
          arr.add(item.value)
          if (maxlength && arr.size > maxlength) {
            this.$message.warning(`最多可选${maxlength}个`)
            return
          }
          if (Array.from(arr).length == this.typeData.filter((e) => e.type !== 'all').length) {
            this.setChange(this.typeData.map((e) => e.value))
            this.$emit(
              'change',
              this.typeData.map((e) => e.value)
            )
          } else {
            this.setChange(Array.from(arr))
            this.$emit('change', Array.from(arr))
          }
        }
      }
    },
    renderButton(item) {
      return (
        <Button style={{ marginRight: '10px', marginTop: '3px', ...this.item.styles }}>
          <Checkbox disabled={item.disabled} value={item.value} onClick={() => this.onCheckItem(item)}>
            {item.name}
          </Checkbox>
        </Button>
      )
    },
  },
  render() {
    return (
      <Checkbox.Group
        disabled={this.mode == 'detail' || this.item.disabled}
        value={this.value}
        style={{ display: 'flex', height: '100%' }}
      >
        {(this.typeData || []).map((e) => this.renderButton(e))}
      </Checkbox.Group>
    )
  },
}
</script>
