import request from '@/utils/request'
import { Message } from 'ant-design-vue'
import { getAction, postAction, putAction, delAction } from '../command/netTool'
/**
 * 获取状态
 */
export const getState = function getState() {
  return {
    // 创建接口地址
    createUrl: '',
    // 列表接口地址
    listUrl: '',
    // 表格当前页条数
    pageSize: 10,
    // 表格当前页
    current: 1,
    // 总数
    total: 10,
    // 表格列表数据
    records: [],
    // 表格查询参数
    params: {},
    // 表格附加查询参数
    paramsValue: {},
    // 详情
    detail: {},
    // 对应type类型
    type: {},
    // 类型接口地址
    typeUrl: '',
    // 判断当前是否是分页列表
    isPage: true,
    // 表格loading
    loading: false,
    // 列表
    list: [],
  }
}

/**
 * 获取列表
 */
export const getList = function getList(
  {
    url = this.listUrl,
    current = this.current,
    pageSize = this.pageSize,
    params = this.params,
    paramsValue = this.paramsValue,
    isPage = this.isPage,
  } = {},
  host = '/api'
) {
  this.loading = true
  return new Promise((resolve) => {
    getAction(
      url,
      {
        current,
        size: pageSize,
        ...params,
        ...paramsValue,
      },
      host
    ).then((e) => {
      this.loading = false
      this.listUrl = url
      this.current = current
      this.params = params
      this.paramsValue = paramsValue
      this.isPage = isPage
      this.total = e.data.total
      //   if (isPage) {
      //     this.records = e.data.records
      //   } else {
      //     this.records = e.data
      //   }
      if (e.data.total !== undefined && e.data.total !== null) {
        this.records = e.data.records
      } else {
        this.records = e.data
      }
      resolve(e)
    })
  })
}

/**
 * 获取树结构
 */
export const getTree = function getTree({ url } = {}) {
  return new Promise((resolve, reject) => {
    getAction(url)
      .then((e) => {
        this.list = e.data
        resolve(e)
      })
      .catch((e) => (this.loading = false || (reject && reject(e))))
  })
}

/**
 * 创建
 */
export const create = function create({ url, params, isPost = true } = {}) {
  this.loading = true
  return new Promise((resolve, reject) => {
    postAction(url, params)
      .then(() => {
        this.loading = false
        Message.success('新建成功')
        isPost && getList.call(this)
        resolve()
      })
      .catch((e) => (this.loading = false || (reject && reject(e))))
  })
}
export const createPut = function create({ url, params, isPost = true } = {}) {
  this.loading = true
  return new Promise((resolve, reject) => {
    putAction(url, params)
      .then(() => {
        this.loading = false
        Message.success('新建成功')
        isPost && getList.call(this)
        resolve()
      })
      .catch((e) => (this.loading = false || (reject && reject(e))))
  })
}
export const detailCreate = function create({ url, params, host } = {}) {
  this.loading = true
  return new Promise((resolve, reject) => {
    postAction(url, params, host)
      .then(() => {
        this.loading = false
        Message.success('新建成功')
        this.$router.back()
        resolve()
      })
      .catch((e) => (this.loading = false || (reject && reject(e))))
  })
}

/**
 * 核销
 */
export const hxGet = function hxGet({ url, params = {}, isPost = true } = {}) {
  return new Promise((resolve, reject) => {
    request({
      url,
      method: 'GET',
      params,
      data: params,
    }).then((e) => {
      if (e.code == 200) {
        Message.success('核销成功')
        isPost && getList.call(this)
        resolve()
      } else {
        Message.error(e.msg)
        reject(e)
      }
    })
  })
}

/**
 * 删除
 */
export const del = function del({ url, params = {}, isPost = true } = {}) {
  return new Promise((resolve, reject) => {
    request({
      url,
      method: 'DELETE',
      params,
      data: params,
    }).then((e) => {
      if (e.code == 200) {
        Message.success('删除成功')
        isPost && getList.call(this)
        resolve()
      } else {
        Message.error(e.msg)
        reject(e)
      }
    })
  })
}

/**
 * 删除
 */
export const delDetail = function delDetail({ url, params = {} } = {}) {
  request({
    url,
    method: 'DELETE',
    params,
  }).then((e) => {
    if (e.code == 200) {
      Message.success('删除成功')
    } else {
      Message.error(e.msg)
    }
  })
}

/**
 * 删除
 */
export const delPost = function delPost({ url, params = {}, isPost = true } = {}) {
  return request({
    url,
    method: 'POST',
    params,
  }).then((e) => {
    if (e.code == 200) {
      Message.success('删除成功')
      isPost && getList.call(this)
    } else {
      Message.error(e.msg)
    }
  })
}

export const delPostArr = function delPostArr({ url, params = {}, isPost = true } = {}) {
  return new Promise((resolve, reject) => {
    postAction(url, params)
      .then(() => {
        Message.success('删除成功')
        isPost && getList.call(this)
        resolve()
      })
      .catch((e) => (this.loading = false || (reject && reject(e))))
  })
}

export const delDelArr = function delDelArr({ url, params = {}, isPost = true } = {}) {
  return new Promise((resolve, reject) => {
    delAction(url, params)
      .then(() => {
        Message.success('删除成功')
        isPost && getList.call(this)
        resolve()
      })
      .catch((e) => (this.loading = false || (reject && reject(e))))
  })
}

/**
 * 删除Put
 */
export const delPut = function delPut({ url, params = {}, isPost = true } = {}) {
  return new Promise((resolve, reject) => {
    putAction(url, params)
      .then(() => {
        Message.success('删除成功')
        isPost && getList.call(this)
        resolve()
      })
      .catch((e) => (this.loading = false || (reject && reject(e))))
  })
}

/**
 * 编辑模式
 */
export const edit = function edit({ url, params = {}, isPost = true, title = '编辑成功', showMessage = true } = {}) {
  this.loading = true
  return new Promise((resolve, reject) => {
    putAction(url, params)
      .then((e) => {
        this.loading = false
        showMessage && Message.success(title)
        isPost && getList.call(this)
        resolve(e)
      })
      .catch((e) => (this.loading = false || (reject && reject(e))))
  })
}

/**
 * 编辑模式Post
 */
export const editPost = function editPost({ url, params = {}, isPost = true } = {}) {
  this.loading = true
  return new Promise((resolve, reject) => {
    postAction(url, params)
      .then(() => {
        this.loading = false
        Message.success('编辑成功')
        isPost && getList.call(this)
        resolve()
      })
      .catch((e) => (this.loading = false || (reject && reject(e))))
  })
}

/**
 * 编辑模式Post
 */
export const editGET = function editGET({ url, params = {} } = {}) {
  this.loading = true
  return new Promise((resolve, reject) => {
    getAction(url, params)
      .then(() => {
        this.loading = false
        Message.success('编辑成功')
        getList.call(this)
        resolve()
      })
      .catch((e) => (this.loading = false || (reject && reject(e))))
  })
}

/**
 * 同步价格
 */
export const syncGET = function editGET({ url, params = {} } = {}) {
  this.loading = true
  return new Promise((resolve, reject) => {
    getAction(url, params)
      .then(() => {
        this.loading = false
        Message.success('同步价格成功')
        getList.call(this)
        resolve()
      })
      .catch((e) => (this.loading = false || (reject && reject(e))))
  })
}

/**
 * 详情编辑模式
 */
export const detailPost = function detailPost({ url, params = {}, host } = {}) {
  this.loading = true
  return new Promise((resolve, reject) => {
    postAction(url, params, host)
      .then(() => {
        this.loading = false
        Message.success('编辑成功')
        this.$router.back()
        resolve()
      })
      .catch((e) => (this.loading = false || (reject && reject(e))))
  })
}
export const detailPut = function detailPut({ url, params = {}, host } = {}) {
  this.loading = true
  return new Promise((resolve, reject) => {
    putAction(url, params, host)
      .then(() => {
        this.loading = false
        Message.success('编辑成功')
        this.$router.back()
        resolve()
      })
      .catch((e) => (this.loading = false || (reject && reject(e))))
  })
}

/**
 * 开启或关闭
 */
export const switchEnable = function toggerEnable({ url, params = {} } = {}) {
  return new Promise((resolve) => {
    request({ url, params, method: 'GET' }).then((e) => {
      if (e.code == 200) {
        Message.success('切换成功')
        getList.call(this)
        resolve()
      } else {
        Message.error(e.msg)
      }
    })
  })
}

/**
 * 获取对应type类型
 */
export const getType = function getType({ url = this.typeUrl, params = {} } = {}) {
  request({
    url,
    method: 'GET',
    params: {
      ...params,
    },
  }).then((e) => {
    this.typeUrl = url
    this.type = e.result.map((e) => ({ name: e.typeName || e.name, value: e.id }))
  })
}

/**
 * 获取对应分页type类型
 */
export const getPageType = function getPageType({ url, params = {} } = {}) {
  request({
    url,
    method: 'GET',
    params: {
      size: 1000,
      ...params,
    },
  }).then((e) => {
    this.typeUrl = url
    this.type = e.result.records.map((e) => ({ name: e.typeName, value: e.id }))
  })
}

/**
 * 获取详情
 */
export const getDetail = function getDetail({ url } = {}) {
  return new Promise((resolve) => {
    getAction('/api' + url).then((e) => {
      this.detail = e.data || this.detail
      resolve(e.data)
    })
  })
}
/**
 * 获取详情
 */
export const getDetail2 = function getDetail({ url } = {}) {
  return new Promise((resolve) => {
    getAction(url, {}, '/bpi').then((e) => {
      this.detail = e.data
      resolve(e.data)
    })
  })
}

export const getChildren = function getChildren(children) {
  return children.map((e) => {
    return {
      ...e,
      label: e.name,
      value: String(e.id),
      children: e.children && e.children.length > 0 ? getChildren(e.children) : null,
    }
  })
}
export const getChildren2 = function getChildren2(children) {
  return children.map((e) => {
    return {
      ...e,
      label: e.name,
      value: e.name,
      children: e.children && e.children.length > 0 ? getChildren2(e.children) : null,
    }
  })
}

/**
 * 获取级联数据类型
 */
export const getCascaderType = function getCascaderType({ url, key = 'cascaderType' } = {}) {
  request({
    url,
  }).then((e) => {
    this[key] = getChildren(e.result)
  })
}

/**
 * 获取区域数据
 */
export const getRegionType = function getCascaderType({ url, key = 'regionType' } = {}) {
  getAction(url).then((e) => {
    this[key] = getChildren(e.data)
  })
}
export const getRegionType2 = function getCascaderType({ url, key = 'regionType' } = {}) {
  getAction(url).then((e) => {
    this[key] = getChildren2(e.data)
  })
}

/**
 * 取消接口
 */
export const cancel = function cancel({ url, params }) {
  return new Promise((resolve, reject) => {
    postAction(url, params)
      .then(() => {
        Message.success('取消成功')
        getList.call(this)
        resolve()
      })
      .catch((e) => (this.loading = false || (reject && reject(e))))
  })
}

/**
 * 编辑模式Post
 */
export const replyPost = function editPost({ url, params = {} } = {}) {
  return new Promise((resolve, reject) => {
    postAction(url, params)
      .then(() => {
        Message.success('已回复')
        getList.call(this)
        resolve()
      })
      .catch((e) => (this.loading = false || (reject && reject(e))))
  })
}

/**
 * 获取所有标签 主体
 */
export const getLabelGroup = async function getLabelGroup({ url } = {}) {
  return new Promise((resolve, reject) => {
    getAction(url)
      .then((e) => {
        if (Array.isArray(e.data) && e.data.length > 0) {
          this.labelGroup = e.data
          resolve()
        } else {
          reject()
        }
      })
      .catch((e) => (this.loading = false || (reject && reject(e))))
  })
}

/**
 * 获取所有标签 code
 */
export const getLabelCodeGroup = async function getLabelCodeGroup({ url, params = {} } = {}) {
  const all = await Promise.all(params.codes.map((e) => getAction(url + `?busId=${params.id}&code=${e.key}`)))
  let arr1 = all.map((e) => ({
    ...e.data,
  }))
  this.labelGroup = arr1
}

/**
 * 搜索-分页
 */
export const searchPage = function search({ url, host = '/api' } = {}) {
  return new Promise((resolve, reject) => {
    getAction(url, {}, host)
      .then((e) => {
        if (e.code == 200) {
          resolve(e.data.records)
        } else {
          resolve([])
        }
      })
      .catch((e) => (this.loading = false || (reject && reject(e))))
  })
}

/**
 * 搜索
 */
export const search = function search({ url, host = '/api' } = {}) {
  return new Promise((resolve, reject) => {
    getAction(url, {}, host)
      .then((e) => {
        if (e.code == 200) {
          resolve(e.data)
        } else {
          resolve([])
        }
      })
      .catch((e) => (this.loading = false || (reject && reject(e))))
  })
}

/**
 * 商品类型
 */
export const getProductTypeList = function getProductTypeList() {
  return new Promise((resolve, reject) => {
    getAction('/api/base/product/getProductTypeList').then((e) => {
      if (e.code == 200) {
        resolve(
          e.data.map((e) => ({
            ...e,
            name: e.moduleName,
            value: e.moduleCode,
          }))
        )
      } else {
        resolve([])
      }
    })
  })
}

/**
 * 农家特产
 */
export const getFarmNativeProductList = function getFarmNativeProductList() {
  return new Promise((resolve, reject) => {
    getAction('/api/commodity/farmNativeProduct/list').then((e) => {
      if (e.code == 200) {
        resolve(
          e.data.map((e) => ({
            ...e,
            value: e.id,
          }))
        )
      } else {
        resolve([])
      }
    })
  })
}
/**
 * 吃好喝好
 */
export const getFarmAgritainmentList = function getFarmAgritainmentList() {
  return new Promise((resolve, reject) => {
    getAction('/api/goods/farmAgritainment/list').then((e) => {
      if (e.code == 200) {
        resolve(
          e.data.map((e) => ({
            ...e,
            value: e.id,
          }))
        )
      } else {
        resolve([])
      }
    })
  })
}
/**
 * 农事体验
 */
export const getFarmFarmingExperienceList = function getFarmFarmingExperienceList() {
  return new Promise((resolve, reject) => {
    getAction('/api/goods/farmFarmingExperience/list').then((e) => {
      if (e.code == 200) {
        resolve(
          e.data.map((e) => ({
            ...e,
            value: e.id,
          }))
        )
      } else {
        resolve([])
      }
    })
  })
}
/**
 * 景点列表
 */
export const getFarmTicketScenicList = function getFarmTicketScenicList() {
  return new Promise((resolve, reject) => {
    getAction('/api/farmTicketScenic/list', {}).then((e) => {
      if (e.code == 200) {
        resolve(
          e.data.map((e) => ({
            ...e,
            value: e.id,
          }))
        )
      } else {
        resolve([])
      }
    })
  })
}
/**
 * 酒店列表
 */
export const getHotelList = function getHotelList() {
  return new Promise((resolve, reject) => {
    getAction('/api/hotel/list', {}).then((e) => {
      if (e.code == 200) {
        resolve(
          e.data.map((e) => ({
            ...e,
            value: e.id,
          }))
        )
      } else {
        resolve([])
      }
    })
  })
}

export default {
  create,
  createPut,
  detailCreate,
  cancel,
  getList,
  getTree,
  del,
  edit,
  editPost,
  detailPost,
  detailPut,
  getState,
  getType,
  getDetail,
  getDetail2,
  getPageType,
  switchEnable,
  getCascaderType,
  getRegionType,
  getRegionType2,
  getLabelGroup,
  getLabelCodeGroup,
  replyPost,
  editGET,
  syncGET,
  delPost,
  delPostArr,
  search,
  searchPage,
  delDetail,
  delPut,
  delDelArr,
  hxGet,
}
