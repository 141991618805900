<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    value: {
      type: String,
      default: () => {},
    },
    typeData: {
      type: Array,
      default: () => [],
    },
    setValue: {
      type: Function,
      default: null,
    },
  },
  methods: {
    handleClick(data) {
      if (this.value.find((e) => e.value == data.value)) {
        this.value.find((e, i) => {
          if (e.value == data.value) {
            const list = this.value
            list.splice(i, 1)
            this.$emit('change', list)
          }
        })
      } else {
        this.$emit('change', [...this.value, data])
      }
    },
  },
  render() {
    return (
      <div class="labels">
        <div class="label_box">
          <div class="label_box_t">{this.item.title || '全部标签'}</div>
          <div class="label_box_s">
            {this.typeData.map((item, index) => (
              <div class={this.value.find((e) => e.value == item.value) && 'on'} onClick={() => this.handleClick(item)}>
                {item.name}
              </div>
            ))}
          </div>
        </div>
        <div class="label_box">
          <div class="label_box_t">已选标签</div>
          <div class="label_box_s">
            {this.value.map((item) => (
              <div onClick={() => this.handleClick(item)}>{item.name} ×</div>
            ))}
          </div>
        </div>
      </div>
    )
  },
}
</script>

<style lang="less" scoped>
.labels {
  .label_box_s {
    border-radius: 3px;
    border: 1px solid #b6b6b6;
    padding: 8px;
    min-height: 70px;
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    > div {
      padding: 0px 8px;
      line-height: 30px;
      height: 30px;
      border-radius: 3px;
      border: 1px solid #cccccc;
      margin-left: 6px;
      margin-bottom: 6px;
      cursor: pointer;
    }

    .on {
      border: 1px solid #1e9eff;
    }
  }
}
</style>
