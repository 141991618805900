import command from '../api/command'
// 本地banner
import localBanner from '../api/localBanner'
// 系统审核，评价审核
import audit from '../api/audit'
// 平台设置，基本信息
import baseInfo from '../api/baseInfo'
// 平台设置，标签管理
import freight from '../api/freight'
// 订单
import order from '../api/order'
export default {
  command,
  localBanner,
  audit,
  baseInfo,
  freight,
  order,
}
